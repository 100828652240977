import { useWorkspace } from '~/stores/workspace'

export default defineNuxtRouteMiddleware(async () => {
  const { $datadog, $pinia } = useNuxtApp()
  const workspace = useWorkspace($pinia)

  if (!process.client) {
    return
  }

  if (!workspace.isRialticAdmin) {
    $datadog.addAction('Access admin - DENIED')

    return abortNavigation()
  }
})
